@use "sass:map";

@import "./../required";

.list-categories a,
.list-categories-l2 > li > div {
    display: block;
}

.list-categories a,
.list-categories-l2 > li > div,
.list-categories-accordion-header {
    padding-top: $nav-link-padding-y;
    padding-bottom: $nav-link-padding-y;
    color: $body-color;
}

.list-categories-accordion-body {
    .list-categories {
        > li {
            border-bottom: none;
        }
    }
}

.list-categories-l2 > li > div,
.list-categories-l3 {
    display: none;
}

.list-categories-l2 {
    > li > a {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-left: calc(40px + #{$spacer});
    }
}

.list-categories {
    &,
    ul {
        @include list-unstyled();
    }

    display: flex;
    flex-direction: column;
    margin: 0;

    a strong {
        flex-grow: 1;
    }

    > li {
        border-bottom: 1px solid $border-color;

        img,
        .link-icon {
            width: 40px;
            height: 40px;
            margin-right: $spacer;
        }

        .link-icon {
            padding: map.get($spacers, 1);
            color: $gray-700;
        }

        > a,
        .list-categories-accordion-header {
            display: flex;
            align-items: center;
            padding-right: $nav-link-padding-x;
            padding-left: $nav-link-padding-x;
        }

        .icon-arrow-right {
            display: none;
        }

        .dropdown-menu {
            display: none;
            margin-top: -$nav-link-padding-y;
            background-color: $white;

            > .container-fluid {
                min-width: auto;
            }
        }

    }

    > .active {
        > a strong,
        .list-categories-link strong {
            color: $primary;
        }
    }

    .item-blog {
        .icon-arrow-right {
            display: block;
        }
    }

    > .show-menu {
        .icon-arrow-right {
            display: block;
        }

        .dropdown-menu {
            display: block;
        }

        > a > .icon-arrow-right {
            transform: rotate(90deg);
        }
    }
}

@include media-breakpoint-up(lg) {
    .list-categories-l3 {
        display: block;

        a {
            padding-top: calc($nav-link-padding-y / 2);
            padding-bottom: calc($nav-link-padding-y / 2);
        }
    }

    .list-categories > li > a {
        justify-content: center;
        padding: ($nav-link-padding-y * 1.6) 0;
    }

    .list-categories a strong {
        position: relative;
        flex-grow: 0;
        font-weight: $font-weight-normal;

        &::after {
            position: absolute;
            right: 0;
            bottom: -13px;
            left: 0;
            height: 2px;
            content: " ";
            background-color: $primary;
            opacity: 0;
            transition: $transition-fade;
        }
    }

    .list-categories-l2 {
        column-gap: $spacer;
        text-align: left;
        columns: 4;

        > li {
            margin-bottom: $spacer;
            font-size: $font-size-sm;
            break-inside: avoid;

            > a,
            > div {
                font-weight: $font-weight-bold;
                color: $gray-900;
                text-transform: uppercase;
            }

            > a {
                padding-left: 0;
            }

            > div {
                display: block;
            }
        }

        a:hover,
        .active {
            text-decoration: underline;
        }
    }

    .list-categories-link {
        display: none;
    }

    .list-categories {
        flex-direction: row;

        .active > strong::after,
        a:hover strong::after {
            opacity: 1;
        }

        > li {
            flex-grow: 1;
            text-align: center;
            border-bottom: 0;

            img {
                display: none;
            }

            &:last-child > a {
                justify-content: flex-end;
            }

            .dropdown-menu {
                position: absolute;
                right: 0;
                left: 0;
                margin-top: 1px;
                border-bottom: 1px solid $border-color-translucent;

                .container-fluid {
                    padding-top: $spacer;
                    padding-bottom: $spacer;
                }
            }

            .list-categories-accordion-header {
                display: none;
            }
            .list-categories-accordion-body {
                display: block;
                padding: 0;
            }
        }

        .item-blog {
            .icon-arrow-right {
                display: none;
            }
        }
    }

    .list-categories > .show-menu {
        .icon-arrow-right {
            display: none;
        }

        a strong::after {
            opacity: 1;
        }
    }
}
