@use "sass:map";

@import "./../required";
@import "bootstrap/scss/navbar";
@import "../../scss/05-components/icon-bars";

$the-header-border-color:       $border-color;

.the-header {
    position: sticky;
    top: 0;
    z-index: $zindex-sticky;
    display: flex;
    flex-direction: column;

    .body-nav-drawer & {
        height: 100vh;

        @include media-breakpoint-up(lg) {
            height: auto;
        }
    }
}

.navbar {
    z-index: 1;
    pointer-events: auto;
    background-color: $white;
    border-bottom: 1px solid $the-header-border-color;

    .container-fluid {
        position: relative;
    }

    .flex-grow-1,
    .nav {
        position: relative;
        z-index: 2;
        display: flex;
        align-items: center;
    }

    .form-search {
        position: relative;
        z-index: 1;
        width: 100%;
        margin-top: $navbar-padding-y;
    }

    .navbar-toggler {
        margin-left: -$navbar-toggler-padding-x;
    }

    .navbar-brand {
        display: inline-flex;
    }

    .body-nav-drawer & {
        .form-search {
            display: none;
        }
    }

    @include media-breakpoint-up(sm) {
        .navbar-toggler {
            margin-right: map.get($spacers, 2);
        }
    }

    @include media-breakpoint-up(lg) {
        padding: $spacer 0;

        .flex-grow-1,
        .nav {
            z-index: 1;
        }

        .flex-grow-1 {
            flex: 1;
        }

        .nav {
            flex: 1;
            justify-content: flex-end;
            order: 10; // 10 because we need to be last
        }

        .form-search {
            position: static;
            margin-top: 0;
            margin-right: map.get($spacers, 4);
        }

        .body-nav-drawer & {
            .form-search {
                display: block;
            }
        }
    }

    @include media-breakpoint-down(lg) {
        .flex-grow-1 {
            flex-grow: 0 !important;
        }

        .nav {
            flex-grow: 1;
            justify-content: space-between;
        }
    }
}

.the-header-menu {
    display: none;
    background-color: $white;
    border-bottom: $border-width solid transparent;

    > .container-fluid {
        padding-right: 0;
        padding-left: 0;
    }

    .body-nav-drawer & {
        display: block;
        flex-grow: 1;
        overflow-y: scroll;
    }

    @include media-breakpoint-up(lg) {
        display: block;
        border-bottom-color: $the-header-border-color;

        > .container-fluid {
            padding-right: calc(#{$grid-gutter-width} / 2);
            padding-left: calc(#{$grid-gutter-width} / 2);
        }
    }
}

.body-nav-drawer {
    overflow: hidden;

    @include media-breakpoint-up(lg) {
        overflow: scroll;
    }
}

html > :not(.search-open):not(.body-nav-drawer) {
    .the-header {
        transition: transform .2s;
        transform: translateY(0);
    }

    &.is-scrolling-down .the-header {
        transform: translateY(-100%);
    }

    @include media-breakpoint-up(lg) {
        .the-header {
            transition: none;
            transform: none;
        }

        &.is-scrolling-down .the-header {
            transform: none;
        }
    }
}

@include media-breakpoint-up(lg) {
    .the-header-menu {
        transition: $transition-base;
        transition-delay: .2s;
    }

    .is-scrolling-down .the-header-menu {
        transform: translateY(-100%);

        .dropdown-menu {
            opacity: 0;
        }
    }
}
